<i18n lang="yaml">
pt:
  options: 'Opções'
  delete: 'Apagar'
  published: 'Automação publicada'
  publishedDescription: 'Disponível para usuários'
  dataSource: 'Origem dos dados'
  viewSource: 'Página associada'
  shortcuts: 'Atalhos'
  cantPublishArchivedWorkflow: 'Você não pode publicar uma automação arquivada'
en:
  options: 'Options'
  delete: 'Delete'
  published: 'Automation published'
  publishedDescription: 'Available to users'
  dataSource: 'Data source'
  viewSource: 'Connected view'
  shortcuts: 'Shortcuts'
  cantPublishArchivedWorkflow: 'You cannot publish an archived automation'
</i18n>
<template>
  <div>
    <v-list
      density="compact"
      class="py-0"
    >
      <v-list-item class="listHeader">
        <v-list-item-title> {{ t('options') }} </v-list-item-title>
      </v-list-item>
    </v-list>

    <v-list
      v-if="currentWorkflow.id"
      density="compact"
    >
      <v-list-item
        :class="{
          'workflow-options__switch-archived': isArchived,
        }"
        @click="onPublishClick"
      >
        <v-list-item-title :title="t('published')">
          {{ t('published') }}
        </v-list-item-title>
        <v-list-item-subtitle :title="t('publishedDescription')">
          {{ t('publishedDescription') }}
        </v-list-item-subtitle>

        <template #append>
          <v-list-item-action end>
            <deck-switch
              :model-value="currentWorkflow.published"
              :aria-label="t('published')"
              :disabled="isArchived"
              @update:model-value="updateWorkflow({ workflowId: currentWorkflow.id, published: $event })"
            />
          </v-list-item-action>
        </template>
      </v-list-item>

      <template v-if="!_isEmpty(currentSheet) || !_isEmpty(currentViews)">
        <v-list-subheader>
          {{ t('shortcuts') }}
        </v-list-subheader>

        <v-list-item
          v-if="!_isEmpty(currentSheet)"
          :to="localePath({ name: 't-tenantSlug-studio-data-id', params: { tenantSlug: $route.params.tenantSlug, id: currentSheet.id } })"
        >
          <v-list-item-title :title="currentSheet.name">
            <v-icon
              size="small"
              start
            >
              fa-database fa-regular
            </v-icon>
            {{ currentSheet.name }}
          </v-list-item-title>

          <v-list-item-subtitle :title="t('dataSource')">
            {{ t('dataSource') }}
          </v-list-item-subtitle>

          <template #append>
            <v-list-item-action end>
              <v-icon>fa-up-right-from-square fa-regular</v-icon>
            </v-list-item-action>
          </template>
        </v-list-item>

        <v-list-item
          v-for="view in currentViews"
          :key="view.id"
          :to="localePath({ name: 't-tenantSlug-studio-pages-id', params: { tenantSlug: $route.params.tenantSlug, id: view.id } })"
        >
          <v-list-item-title :title="view.page_name">
            <v-icon
              size="small"
              start
            >
              fa-browser fa-regular
            </v-icon>
            {{ view.page_name }}
          </v-list-item-title>
          <v-list-item-subtitle :title="t('viewSource')">
            {{ t('viewSource') }}
          </v-list-item-subtitle>

          <template #append>
            <v-list-item-action>
              <v-icon>fa-up-right-from-square fa-regular</v-icon>
            </v-list-item-action>
          </template>
        </v-list-item>
      </template>
    </v-list>
  </div>
</template>
<script>
import { mapActions, mapGetters } from '~/assets/javascript/modules/vuex';

export default {
  name: 'WorkflowsOptions',
  setup() {
    return {
      t: useI18n().t,
      ...mapGetters('workflows', ['currentWorkflow', 'currentViews', 'isArchived', 'currentSheet']),
      ...mapActions('workflows', ['updateWorkflow']),
    };
  },
  methods: {
    onPublishClick() {
      if (this.isArchived) {
        this.$notifier.showMessage({
          content: this.t('cantPublishArchivedWorkflow'),
        });
      } else {
        this.updateWorkflow({ workflowId: this.currentWorkflow.id, published: !this.currentWorkflow.published });
      }
    },
  },
};
</script>

<style lang="scss">
.workflow-options__switch-archived {
  opacity: 0.3;
}
</style>

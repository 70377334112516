<i18n lang="yaml">
pt:
  explainStep: "Uma ação será realizada toda vez que a automação for disparada"
  chooseAction: "Escolha a ação"
  create: "Criar"
  addAction: "Nova ação"
  group:
    AI: "IA"
    Api: "API"
    Communication: "Comunicação"
    ControlFlow: "Fluxo de controle"
    FileManipulation: "Manipulação de arquivos"
    KnowledgeBase: "Base de conhecimento"
    Navigation: "Navegação"
    Others: "Outros"
    RecordManipulation: "Manipulação de entradas"
    Slack: "Slack"
    UserManagement: "Gerenciamento de usuário"
en:
  explainStep: "An action will be executed every time the automation is triggered"
  chooseAction: "Choose the action"
  create: "Create"
  addAction: "New action"
  group:
    AI: "AI"
    Api: "API"
    Communication: "Communication"
    ControlFlow: "Control flow"
    FileManipulation: "File manipulations"
    KnowledgeBase: "Knowledge base"
    Navigation: "Navigation"
    Others: "Others"
    RecordManipulation: "Record manipulation"
    Slack: "Slack"
    UserManagement: "User management"
</i18n>

<template>
  <div
    :class="stepCreatorClasses"
    class="step-creator"
  >
    <div class="step-creator__arrow">
      <div
        :class="{ 'step-creator__icon-wrapper--last-step': isLastStep && !connectorOnly }"
        class="step-creator__icon-wrapper step-creator__icon-wrapper--start"
      />
      <div
        v-if="!isLastStep || connectorOnly"
        class="step-creator__icon-wrapper step-creator__icon-wrapper--end"
      >
        <deck-icon
          name="chevron-down"
          size="small"
        />
      </div>
    </div>

    <template v-if="!connectorOnly">
      <div
        v-if="!showStepCreator"
        class="step-creator__add-step"
      >
        <deck-button
          v-test-id="'addStepButton'"
          is-ready
          kind="ghost"
          icon="circle-plus"
          icon-kind="regular"
          :text="`${nextDisplayIndex}. ${t('addAction')}`"
          :tooltip-props="{ position: 'right' }"
          @click="openStepCreator = !openStepCreator"
        />
      </div>

      <deck-expander
        :model-value="showStepCreator"
        class="step-creator__form"
      >
        <v-form
          ref="newStepForm"
          @submit.prevent="createStep()"
        >
          <v-card elevation="0">
            <v-card-title class="text-h6 d-flex align-items-center">
              <deck-icon
                name="plus-circle"
                class="mr-2"
              />
              {{ `${nextDisplayIndex}. ${t('addAction')}` }}
              <v-spacer />
              <deck-button
                is-ready
                kind="ghost"
                icon="xmark"
                @click="openStepCreator = !openStepCreator"
              />
            </v-card-title>

            <v-card-text class="text--primary">
              <p>{{ t('explainStep') }}</p>

              <deck-select
                v-model="actionTypeId"
                unordered-items
                :items="actionList"
                :label="t('chooseAction')"
              />
            </v-card-text>

            <v-card-actions>
              <v-spacer />
              <deck-button
                type="submit"
                :disabled="!Boolean(actionTypeId) || isCreating"
                :loading="isCreating"
              >
                {{ t('create') }}
              </deck-button>
            </v-card-actions>
          </v-card>
        </v-form>
      </deck-expander>
    </template>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from '~/assets/javascript/modules/vuex';
import { dataMixin } from '~/mixins/dataMixin';

export default {
  name: 'StepCreator',
  mixins: [dataMixin],
  props: {
    step: { type: Object, default: () => ({}) },
    parentId: { type: String, default: null },
    isTrigger: { type: Boolean, default: false },
    isRoot: { type: Boolean, default: false },
    isFirstLoopStep: { type: Boolean, default: false },
    isFirstConditionalStep: { type: Boolean, default: false },
    conditionalType: { type: String, default: null },
    // eslint-disable-next-line vue/no-unused-properties
    prepend: { type: Boolean, default: false },
    connectorOnly: { type: Boolean, default: false },
  },
  setup() {
    return {
      t: useI18n().t,
      ...mapGetters('workflows', ['currentWorkflow']),
      ...mapMutations('workflows', ['setStepVisibility']),
      ...mapActions('workflows', ['loadWorkflows', 'reloadCurrentWorkflow']),
    };
  },
  data() {
    return {
      // eslint-disable-next-line vue/no-unused-properties
      viewsAvailable: [],
      // eslint-disable-next-line vue/no-unused-properties
      loadingViews: false,
      actionTypeId: null,
      openStepCreator: false,
      isCreating: false,
    };
  },
  computed: {
    stepCreatorClasses() {
      return {
        'step-creator--open': this.showStepCreator,
        'step-creator--empty-loop': this.isEmptyLoopStep,
        'step-creator--last-root-step': this.isLastRootStep,
        'step-creator--last-conditional-step': this.isLastConditionalStep,
        'step-creator--connector-only': this.connectorOnly,
      };
    },
    showStepCreator() {
      return this.currentWorkflow.sorted_steps.length === 0 || this.openStepCreator;
    },
    availableStepTypes() {
      const types = this.currentWorkflow.available_step_types;
      const isZazosUser = this.$auth?.user?.username?.endsWith('@zazos.com');

      return types.filter((type) => {
        if (type.key === 'AskChatGPT' && !isZazosUser) {
          return false;
        }

        return true;
      });
    },
    actionList() {
      const groupedItems = this.stepItems(this.availableStepTypes, this.$i18n.locale).reduce((acc, item) => {
        acc[item.group] ||= {};
        acc[item.group].label = this.t(`group.${item.group}`);
        acc[item.group].items ||= [];
        acc[item.group].items.push(item);

        return acc;
      }, {});

      return Object.values(groupedItems);
    },
    currentParentStep() {
      // sometimes it is not loaded yet and will cause an error
      if (!this.currentWorkflow.stepsById || !this.parentId) return null;

      return this.currentWorkflow.stepsById?.[this.parentId];
    },
    isEmptyLoopStep() {
      return this.parentId && this.currentParentStep.sorted_steps.length === 0;
    },
    indexToCreate() {
      if (this.isFirstLoopStep || this.isTrigger || this.isFirstConditionalStep) return 0;

      return this.step.index;
    },
    isLastRootStep() {
      return this.isRoot
      && !this.isTrigger
      && !this.isFirstLoopStep
      && this.currentWorkflow.sorted_steps.length === this.step.index;
    },
    hasConditionalParent() {
      return this.currentParentStep?.action_type === 'Conditional';
    },
    isLastConditionalStep() {
      if (!this.hasConditionalParent) return false;

      let stepsOrder = [];

      if (this.conditionalType === 'true') {
        stepsOrder = this.currentParentStep.steps_order_true_condition;
      }

      if (this.conditionalType === 'false') {
        stepsOrder = this.currentParentStep.steps_order_false_condition;
      }

      return stepsOrder.length === stepsOrder.findIndex(id => id === this.step.id) + 1;
    },
    isLastStep() {
      let stepsLength = this.step.sorted_steps?.length || 0;

      if (this.isRoot) {
        stepsLength = this.currentWorkflow.sorted_steps.length;
      }

      if (this.parentId) {
        stepsLength = this.currentParentStep.sorted_steps.length;
      }

      if (this.isFirstLoopStep || this.isTrigger) {
        return stepsLength === 0;
      }

      if (this.hasConditionalParent) return this.isLastConditionalStep;

      return stepsLength === this.step.index || stepsLength === 0;
    },
    currentStepIndex() {
      return Number(this.step.displayIndex?.slice(-1));
    },
    nextDisplayIndex() {
      if (this.isFirstLoopStep || this.isFirstConditionalStep) {
        return `${this.currentParentStep.displayIndex}.1`;
      }

      if (this.isTrigger) {
        return '1';
      }

      return `${this.step.displayIndex?.slice(0, -1)}${this.currentStepIndex + 1}`;
    },
  },
  methods: {
    async createStep() {
      if (this.$refs.newStepForm.validate()) {
        try {
          this.isCreating = true;

          const params = {
            ...this.parentId && { parent_workflow_step_id: this.parentId },
            ...this.conditionalType && { condition: this.conditionalType },
            workflow_id: this.currentWorkflow.id,
            action_type: this.actionTypeId,
            index: this.indexToCreate,
          };

          const { id } = await this.$api.$post('workflow_steps/', { body: params });
          this.setStepVisibility({ stepId: id, visible: true });

          window.analytics.track('stepCreated', _pick(params, ['action_type', 'condition']));
          this.actionTypeId = null;
          this.openStepCreator = false;

          await this.reloadCurrentWorkflow();
          await this.loadWorkflows();
        } catch (error) {
          this.$errorRescue(this, error, 'createStep');
        } finally {
          this.isCreating = false;
        }
      }
    },
  },
};
</script>

<style lang="scss">
@mixin show-add-step {
  --step-creator-height: var(--step-creator-hover-height);
  --step-creator-add-step-opacity: 1;
  --step-creator-add-step-scale: 1;
  --step-creator-arrow-color: rgb(var(--v-theme-primary));
}

.step-creator {
  --step-creator-transition: 200ms cubic-bezier(0.25, 0.8, 0.5, 1);
  --step-creator-hidden-height: 32px;
  --step-creator-hover-height: 52px;
  --step-creator-height: var(--step-creator-hidden-height);
  --step-creator-add-step-opacity: 0;
  --step-creator-add-step-scale: 0;
  --step-creator-arrow-color: #cccccc;

  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: var(--step-creator-height);
  margin-inline: auto;
  z-index: 1;
  transition: min-height var(--step-creator-transition);
}

.step-creator--open {
  --step-creator-arrow-color: rgb(var(--v-theme-primary));
}

:is(
  .step-creator:hover,
  .step-creator--empty-loop,
  .step-creator--last-root-step,
  .step-creator--last-conditional-step
):not(.step-creator--connector-only){
  @include show-add-step;
}

.step-creator__arrow {
  position: absolute;
  top: -6px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: calc(100% + 8px);
  color: var(--step-creator-arrow-color);
  transition: color var(--step-creator-transition);
}

.step-creator__icon-wrapper {
  display: flex;
  justify-content: center;

  &::before {
    content: '';
    position: absolute;
    width: 2px;
    height: max((var(--step-creator-hidden-height) - 4px) / 2, calc(50% - 16px));
    background: currentColor;
  }
}

.step-creator__icon-wrapper--start::before {
  top: 8px;
}

.step-creator__icon-wrapper--end::before {
  bottom: 4px;
}

.step-creator__icon-wrapper--last-step {
  opacity: var(--step-creator-add-step-opacity);
  transition: opacity var(--step-creator-transition);
}

.step-creator__add-step {
  position: absolute;
  margin-inline: auto;
  opacity: var(--step-creator-add-step-opacity);
  transform: scale(var(--step-creator-add-step-scale));
  transition:
    opacity var(--step-creator-transition),
    transform var(--step-creator-transition)
  ;
}

.step-creator__form {
  flex: 1;
  margin-block: 24px;
}
</style>
